.txt-cut[data-v-4097f62e] {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.grid[data-v-4097f62e] {
  padding: 0 15px;
  height: calc(100vh - 165px);
  overflow: hidden scroll;
}
.grid-col[data-v-4097f62e] {
  margin-bottom: 16px;
}
.grid .el-card[data-v-4097f62e] {
  border-radius: 14px;
}
.grid .el-card[data-v-4097f62e]:hover {
  border-color: #388ef8;
}
.grid-item[data-v-4097f62e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.grid-item .icon[data-v-4097f62e] {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
}
.grid-item .info[data-v-4097f62e] {
  margin-left: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  word-break: break-all;
}
.grid-item .info .name[data-v-4097f62e] {
  color: #388ef8;
  font-size: 15px;
  margin-bottom: 4px;
}
.grid-item .info .key[data-v-4097f62e] {
  font-size: 13px;
  color: #999;
}